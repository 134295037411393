import React, { useState } from "react";
import bgimg1 from "../../Assets/images/bgimg1.png";
import groupmobile1 from "../../Assets/images/groupmobie1.png";
import iPhone14Pro from "../../Assets/images/iPhone14Pro.png";
import iosimg from "../../Assets/images/iosimg.png";
import androidimg from "../../Assets/images/androidimg.png";
import bgimg2 from "../../Assets/images/bgimg2.png";
import bgimg3 from "../../Assets/images/bgimg3.png";
import bgimg4 from "../../Assets/images/bgimg4.png";
import iphone1 from "../../Assets/images/iphone1.png";
import iphone2 from "../../Assets/images/iphone2.png";
import iphone3 from "../../Assets/images/iphone3.png";
import timer from "../../Assets/images/timer.png";
import discountshape from "../../Assets/images/discountshape.png";
import convenience from "../../Assets/images/convenience.png";
import quality from "../../Assets/images/quality.png";
import { RiSubtractFill } from "react-icons/ri";
import { RiAddFill } from "react-icons/ri";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../App.css";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),

  email: Yup.string().email("Invalid email").required("Email is required"),

  message: Yup.string().required("Message is required"),
});

function Homepage() {
  const [selected, setSelected] = useState(null);

  const toggleAnswer = (index) => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  function ScrollToSection() {
    const { hash } = useLocation();

    React.useEffect(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, [hash]);

    return null;
  }

  const questions = [
    {
      question: "What types of car washes do you offer?",
      answer:
        "We have a list of car washes for you to choose from. You can see the service they offer, the price for the service, their rating and revies, you can choose as your convenience.",
    },
    {
      question: "How long does a car wash take?",
      answer:
        "Though it depends on the type of service and service provider, typically it takes a minimum of 30 minutes.",
    },
    {
      question: "What should I do if I'm not satisfied with the car wash?",
      answer:
        "You can contact us any time if you are not satisfied with the service provided and we always urge to satisfy you. And don't forget to rate and review anytime you got a service as it can help to improve!",
    },
    {
      question: "Can you wash specialty vehicles like RVs or boats?",
      answer:
        "Yes, we have a list of service providers that can meet your needs. you can search by service, or directly contact us.",
    },
    {
      question: "Do you offer any additional services?",
      answer:
        "We have service providers that offer additional services. You can search by service, or directly contact us.",
    },
  ];

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 500,
    // autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      resetForm();
    },
  });

  return (
    <>
      <ScrollToSection />

      <section>
        <div
          className="bg-image w-full h-fit md:h-auto h-auto bg-no-repeat bg-cover "
          style={{ backgroundImage: `url(${bgimg1})` }}
        >
          <div className="containers sm:px-5">
            <div className="text-white text-center sm1:py-20 py-10">
              <h2 className=" 4xl:leading-[62px] 4xl:text-[40px] 2xl:text-4xl  md1:text-3xl sm1:text-2xl text-xl  leading-8 font-bold 4xl:mx-60 xl:mx-48 lg:mx-36 md:mx-16 sm1:mx-10 sm:mx-5 mb-6">
                Car Wash Near Me: Your One-Stop Solution for Finding the Best
                Car Washes in the UAE!
              </h2>
              <p className="font-medium leading-7 4xl:text-lg lg:text-base sm1:text-sm text-base  4xl:mx-64 2xl:mx-52 xl:mx-36 lg:mx-44 md:mx-20 sm1:mx-14 sm:mx-5">
                Welcome to Car Wash Near Me-We make it easy for you to discover
                and book the perfect car wash nearby, no matter where you are in
                the UAE. Whether you're looking for a quick wash, eco-friendly
                services, or premium detailing, we've got you covered.
              </p>
            </div>
            <img
              src={groupmobile1}
              alt="groupmobile"
              className="4xl:w-[1450px] 4xl:ml-14 w-full 4xl:h-[621px] 2xl:h-[600px] xl:h-[500px] lg:h-[400px] md:h-[390px] md1:h-[300px] h-[250px] sm1:block hidden"
            />
            <div className="flex justify-center">
              <img
                src={iPhone14Pro}
                alt="iphone"
                className="sm1:hidden block h-[500px]  sm:w-80 xs:w-72 w-full"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="bg-[#4169E1] text-white">
          <div className="containers md:py-[100px] py-14 sm:px-5">
            <div className="grid grid-cols-12 4xl:gap-x-28 2xl:gap-x-20  sm1:gap-x-6 sm1:gap-y-0 gap-y-10 items-center">
              <div className="sm1:col-span-6 col-span-12">
                <h2 className="font-medium 4xl:text-[40px] 2xl:text-4xl  md1:text-3xl sm1:text-2xl text-xl  4xl:leading-[62px] md:leading-10 sm1:leading-7 leading-8 2xl:mb-6 md:mb-4 sm1:mb-2 mb-6">
                  Download Car Wash Near Me Mobile App Now
                </h2>
                <p className="2xl:leading-7 sm1:leading-5 leading-7  2xl:text-lg lg:text-base md:text-sm sm1:text-xs text-base  font-medium 2xl:w-[480px] lg:w-[420px] 4xl:mb-10 2xl:mb-8 lg:mb-6 sm1:mb-3 mb-6">
                  Ready to give your car the care it deserves?---Download Now!
                </p>
                <div className="flex justify-start items-center xl:gap-x-12 md:gap-x-8 md1:gap-x-5 sm1:gap-x-3 xs:gap-x-5 ">
                  <div>
                    <h2 className=" 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm1:text-lg xs:text-xl text-lg 2xl:leading-[52px] lg:leading-10 sm1:leading-8 leading-10 sm1:font-bold font-medium">
                      1 Millions+
                    </h2>
                    <p className="font-medium 2xl:text-lg lg:text-base md:text-sm sm1:text-xs text-sm 2xl:leading-7 sm1:leading-5 leading-4 4xl:mt-4 md:mt-3 sm1:mt-2 mt-3">
                      Worldwide Active User
                    </p>
                  </div>
                  <div className="border 2xl:h-24 h-20 sm1:mx-0 mx-3"></div>
                  <div>
                    <h2 className=" 2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm1:text-lg xs:text-xl text-lg 2xl:leading-[52px] lg:leading-10 sm1:leading-8 leading-10 sm1:font-bold font-medium">
                      8000+
                    </h2>
                    <p className="font-medium 2xl:text-lg lg:text-base md:text-sm sm1:text-xs text-sm 2xl:leading-7 sm1:leading-5 leading-4 4xl:mt-4 md:mt-3 sm1:mt-2 mt-3">
                      Service Provider
                    </p>
                  </div>
                </div>
              </div>
              <div className="sm1:col-span-6 col-span-12  ">
                <div className="sm1:flex grid items-center 2xl:gap-x-10 lg:gap-x-8 md1:gap-x-6 sm1:gap-x-5 sm1:gap-y-0 gap-y-8 sm1:place-items-start place-items-center">
                  <img
                    src={iosimg}
                    alt="iosimg"
                    className=" xl:h-60 xl:w-60 lg:h-[220px] lg:w-[205px] md:h-44 md:w-[170px] md1:w-[140px] md1:h-36 sm1:w-[105px] sm1:h-[120px] xs:w-56 xs:h-56 h-52"
                  />
                  <img
                    src={androidimg}
                    alt="androidimg"
                    className="xl:h-60 xl:w-60 lg:h-[220px] lg:w-[205px] md:h-44 md:w-[170px] md1:w-[140px] md1:h-36 sm1:w-[105px] sm1:h-[120px] xs:w-56 xs:h-56 h-52"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="howitwork">
        <div className="bg-[#F9F9F9]">
          <div className="containers md1:py-[100px] py-14 sm:px-5">
            <div className="md:mb-12 mb-10">
              <h2 className=" 4xl:text-[40px] 2xl:text-4xl  md1:text-3xl sm1:text-2xl text-xl  leading-10 font-medium text-center text-[#4169E1] underline md:decoration-3 decoration-2 underline-offset-8 ">
                How it Works
              </h2>
            </div>
            <div className=" md1:flex md1:flex-start lg:mb-10 md1:mb-8 mb-6  md1:gap-x-8 md1:gap-y-0  grid place-items-center gap-y-6">
              <div>
                <div className="relative flex justify-center items-center">
                  <div className="bg-[#4169E1] rounded-full  md:h-16 md:w-16 md1:w-14 md1:h-14 w-16 h-16 border-8 border-[#F9F9F9] rounded-full flex items-center justify-center text-white font-bold leading-10  md:text-3xl text-xl -mb-8">
                    1
                  </div>
                </div>
                <div className=" inline-flex items-center font-medium rounded-3xl bg-white 4xl:w-[540px] 2xl:w-[520px] 2xl:h-[280px] xl:w-[460px] xl:h-60 lg:w-[400px] lg:h-[230px] md:w-[360px] md:h-[200px] md1:w-[305px] md1:h-[190px] sm:w-[400px] sm:h-[230px] xs:w-[350px] w-auto ">
                  <div>
                    <h2 className="font-semibold 2xl:text-3xl lg:text-2xl md1:text-lg sm:text-xl text-lg text-center 2xl:px-10 lg:px-9 sm1:px-4 px-5 xl:leading-10 lg:leading-9 md1:leading-6 sm:leading-8 leading-6 2xl:mb-5 xl:mb-4 md1:mb-3 xs:mb-4 mb-3 sm:mt-5 mt-10">
                      Download the app & create your account.
                    </h2>
                    <p className="font-semibold  2xl:text-lg lg:text-base md1:text-sm sm:text-base text-sm lg:leading-7 leading-5 4xl:px-20 xl:px-12 lg:px-7 sm1:px-4 px-5 text-center text-[#667085] mb-4">
                      Begin by downloading our app and setting up your account.
                      It’s quick and easy to get started with just a few taps.
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div className="relative flex justify-center items-center">
                  <div className="bg-[#4169E1] rounded-full md:h-16 md:w-16 md1:w-14 md1:h-14 w-16 h-16 border-8 border-[#F9F9F9] rounded-full flex items-center justify-center text-white font-bold leading-10 md:text-3xl text-xl  -mb-8">
                    2
                  </div>
                </div>
                <div className=" inline-flex items-center font-medium rounded-3xl bg-white 4xl:w-[540px] 2xl:w-[520px] 2xl:h-[280px] xl:w-[460px] xl:h-[240px] lg:w-[400px] lg:h-[230px] md:w-[360px] md:h-[200px] md1:w-[305px] md1:h-[190px] sm:w-[400px] sm:h-[230px] xs:w-[350px] w-auto">
                  <div>
                    <h2 className="font-semibold 2xl:text-3xl lg:text-2xl md1:text-lg sm:text-xl text-lg text-center 2xl:px-10 lg:px-9 sm1:px-4 px-5 xl:leading-10 lg:leading-9 md1:leading-6 sm:leading-8 leading-6 2xl:mb-5 xl:mb-4 md1:mb-3 mb-4 sm:mt-5 mt-10">
                      Choose your car wash service and schedule a time.
                    </h2>
                    <p className="font-semibold 2xl:text-lg lg:text-base md1:text-sm sm:text-base text-sm lg:leading-7  leading-5 4xl:px-20 xl:px-12 lg:px-7 sm1:px-4 px-5 text-center text-[#667085] mb-4">
                      Browse our range of car wash services and choose the one
                      that suits your needs. Schedule a convenient time for our
                      team to visit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" mx-auto md1:flex md1:gap-x-8 md1:gap-y-0 md1:justify-end grid place-items-center gap-y-6">
              <div>
                <div className="relative flex justify-center items-center">
                  <div className="bg-[#4169E1] rounded-full md:h-16 md:w-16 md1:w-14 md1:h-14 w-16 h-16 border-8 border-[#F9F9F9] rounded-full flex items-center justify-center text-white font-bold leading-10 md:text-3xl text-xl  -mb-8">
                    3
                  </div>
                </div>

                <div className=" inline-flex items-center font-medium rounded-3xl bg-white 4xl:w-[540px]  2xl:w-[520px] 2xl:h-[280px] xl:w-[460px] xl:h-60 lg:w-[400px] lg:h-[250px]  md:w-[360px] md:h-[200px] md1:w-[305px] md1:h-[190px] sm:w-[400px] sm:h-[230px] xs:w-[350px] w-auto">
                  <div>
                    <h2 className="font-semibold 2xl:text-3xl lg:text-2xl md1:text-lg sm:text-xl text-lg text-center 2xl:px-10 lg:px-9  px-5 xl:leading-10 lg:leading-9 md1:leading-6 sm:leading-8 leading-6 2xl:mb-5 xl:mb-4 md:mb-3 mb-4  sm:mt-5 mt-10">
                      Receive the service wherever you desire.
                    </h2>
                    <p className="font-semibold 2xl:text-lg lg:text-base md1:text-sm sm:text-base text-sm lg:leading-7 leading-5 4xl:px-20 xl:px-12 lg:px-7 sm1:px-4 px-5 text-center text-[#667085] mb-4">
                      Regardless of the service you select, be it an in-shop or
                      an on-demand car wash, our professional partners are
                      dedicated to meeting your needs.
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div className="relative flex justify-center items-center">
                  <div className="bg-[#4169E1] rounded-full md:h-16 md:w-16 md1:w-14 md1:h-14 w-16 h-16 border-8 border-[#F9F9F9] rounded-full flex items-center justify-center text-white font-bold leading-10 md:text-3xl text-xl  -mb-8">
                    4
                  </div>
                </div>
                <div className="inline-flex items-center font-medium rounded-3xl bg-white 4xl:w-[540px] 2xl:w-[520px] 2xl:h-[280px] xl:w-[460px] xl:h-60 lg:w-[400px] lg:h-[250px]   md:w-[360px] md:h-[200px] md1:w-[305px] md1:h-[190px] sm:w-[400px] sm:h-[230px] xs:w-[350px] w-auto">
                  <div>
                    <h2 className="font-semibold 2xl:text-3xl lg:text-2xl md1:text-lg sm:text-xl text-lg text-center 2xl:px-10 lg:px-9 sm1:px-4 px-5 xl:leading-10 lg:leading-9 md1:leading-6 sm:leading-8 leading-6 2xl:mb-5 xl:mb-4 md1:mb-3 mb-4 xl:pt-0  mt-10">
                      Enjoy a sparkling clean car & seamless service experience.
                    </h2>
                    <p className="font-semibold 2xl:text-lg lg:text-base md1:text-sm sm:text-base text-sm lg:leading-7 leading-5 4xl:px-16 xl:px-12 lg:px-7 sm1:px-4 px-5 text-center text-[#667085] mb-4">
                      Once the service is complete, enjoy the satisfaction of a
                      spotless car and a seamless service experience, all from
                      the convenience of your app.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div
          className=" contain 4xl:pb-[280px] 2xl:pb-[290px] xl:pb-[210px] md:pb-[170px] md1:pb-[150px] sm1:pb-[190px] pb-[200px]"
          style={{ backgroundImage: `url(${bgimg2})` }}
        >
          <div className="containers lg:py-[100px] md1:py-24 py-14 sm:px-5">
            <h2 className="text-white font-semibold  lg:text-3xl md1:text-2xl sm1:text-xl text-lg 2xl:leading-10 leading-8 underline md:decoration-3 decoration-2 underline-offset-8 decoration-[#4169E1]">
              Best Feature
            </h2>

            <p className="font-medium lg:mt-6 mt-3 lg:mb-7 4xl:text-[40px] 2xl:text-4xl  md1:text-3xl sm1:text-2xl text-xl  4xl:leading-[62px] 2xl:leading-10 leading-10 text-white">
              <span className="text-[#4169E1]">Essential Features </span> of the
              Car Wash Mobile App
            </p>
          </div>
        </div>
        <div className="containers md:pb-14 pb-10 sm:px-5">
          <div className="md1:grid md1:grid-cols-12 xl:gap-x-10 lg:gap-x-8 gap-x-6 relative z-10 4xl:-mt-[350px] 2xl:-mt-80 xl:-mt-[280px] md:-mt-60 md1:-mt-[220px] md1:block hidden">
            <div className="col-span-4">
              <div className="card rounded-3xl shadow-xl xl:px-9 md:px-6 px-5 bg-white 4xl:h-[700px] 2xl:h-[650px] xl:h-[590px] lg:h-[540px] md1:h-[470px] ">
                <img
                  src={iphone1}
                  alt="iphone1"
                  className=" w-full 4xl:h-[520px] 2xl:h-[490px] xl:h-[410px] lg:h-[330px] md1:h-[300px]"
                />
                <div className="lg:py-7 py-5">
                  <h2 className="text-center font-semibold 4xl:text-[28px] 2xl:text-2xl lg:text-xl md:text-lg md1:text-base 4xl:leading-8 lg:mb-5 md1:mb-3 text-[#4169E1]">
                    Discover Service Providers
                  </h2>
                  <p className="text-center font-medium text-[#667085] 4xl:text-xl lg:text-base md:text-xs md1:text-[11px]">
                    Discover trusted service providers nearby and choose the
                    best for your car wash needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="card rounded-3xl shadow-xl xl:px-9 lg:px-3 md:px-4 px-2  bg-white 4xl:h-[700px] 2xl:h-[650px] xl:h-[590px] lg:h-[540px] md1:h-[470px] ">
                <div className="lg:py-7 py-5">
                  <h2 className="text-center font-semibold 4xl:text-[28px] 2xl:text-2xl lg:text-xl md:text-lg md1:text-base  4xl:leading-8 lg:mb-5 md1:mb-3 text-[#4169E1]">
                    Services in New Offers
                  </h2>
                  <p className="text-center font-medium text-[#667085] 4xl:text-xl lg:text-base md:text-xs md1:text-[11px] ">
                    Explore our new offers for top-notch services at unbeatable
                    prices.
                  </p>
                </div>
                <img
                  src={iphone2}
                  alt="iphone2"
                  className="px-2 w-full 4xl:h-[536px]  2xl:h-[495px] xl:h-[438px] lg:h-[364px] md:h-[343px] md1:h-[361px] "
                />
              </div>
            </div>
            <div className="col-span-4">
              <div className="card rounded-3xl shadow-xl xl:px-9 md:px-6 px-5 bg-white 4xl:h-[700px] 2xl:h-[650px] xl:h-[590px] lg:h-[540px] md1:h-[470px] ">
                <img
                  src={iphone3}
                  alt="iphone3"
                  className=" w-full 4xl:h-[520px]  2xl:h-[490px] xl:h-[410px] lg:h-[330px] md1:h-[300px]"
                />
                <div className="lg:py-7 py-5">
                  <h2 className="text-center font-semibold 4xl:text-[28px] 2xl:text-2xl lg:text-xl md:text-lg md1:text-base  4xl:leading-8 lg:mb-5 mb-3 text-[#4169E1]">
                    Book Your Slot
                  </h2>
                  <p className="text-center font-medium text-[#667085] 4xl:text-xl lg:text-base md:text-xs md1:text-[11px]">
                    Book your slot now for a convenient and hassle-free car
                    wash.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="containers pb-10">
          <div className=" block md1:hidden relative z-10 sm1:-mt-60 -mt-[250px] ">
            <div>
              <Slider {...settings}>
                <div>
                  <div className="card rounded-3xl shadow-xl sm1:px-3 px-6 bg-white xs:h-[530px] h-[460px] sm1:mr-3 mx-3 mb-10">
                    <img
                      src={iphone1}
                      alt="iphone1"
                      className="w-full sm1:px-3 sm1:h-[340px] xs:h-[390px] h-[300px]"
                    />
                    <div className="py-5">
                      <h2 className="text-center font-semibold sm:text-lg leading-5 mb-3 text-[#4169E1]">
                        Discover Service Providers
                      </h2>
                      <p className="text-center font-medium leading-4 sm:text-base text-sm text-[#667085] ">
                        Discover trusted service providers nearby and choose the
                        best for your car wash needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="card rounded-3xl shadow-xl sm1:px-2 px-6 bg-white  xs:h-[530px] h-[460px] sm1:mx-3 mx-3">
                    <div className="py-5 ">
                      <h2 className="text-center font-semibold sm:text-lg text-base leading-5 mb-3 text-[#4169E1]">
                        Services in New Offers
                      </h2>
                      <p className="text-center font-medium leading-4 sm:text-base text-sm  text-[#667085] ">
                        Explore our new offers for top -notch services <br /> at
                        unbeatable prices.
                      </p>
                    </div>
                    <img
                      src={iphone2}
                      alt="iphone2"
                      className="sm1:px-3 w-full sm1:h-[378px] sm:h-[402px] xs:h-[426px] h-[340px] "
                    />
                  </div>
                </div>
                <div>
                  <div className="card rounded-3xl shadow-xl px-6  bg-white  xs:h-[530px] h-[460px] sm1:ml-3 mx-3 ">
                    <img
                      src={iphone3}
                      alt="iphone3"
                      className=" w-full sm1:h-[340px] xs:h-[390px] h-[300px] "
                    />
                    <div className="py-6">
                      <h2 className="text-center font-semibold sm:text-lg text-base leading-5 mb-3 text-[#4169E1]">
                        Book Your Slot
                      </h2>
                      <p className="text-center font-medium text-[#667085] leading-4 sm:text-base text-sm ">
                        Book your slot now for a convenient and hassle-free car
                        wash.
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div
          className="text-white bg-cover bg-no-repeat "
          style={{ backgroundImage: `url(${bgimg3})` }}
        >
          <div className="containers md1:py-[100px] py-14">
            <p className=" font-medium 4xl:text-[40px] 2xl:text-4xl  md1:text-3xl sm1:text-2xl text-xl  2xl:leading-[62px] leading-10  mb-10">
              Why chose Car Wash Near Me?
            </p>

            <div className="grid sm1:grid-cols-12 lg:gap-x-10 sm1:gap-x-8 lg:gap-y-0 sm1:gap-y-10 gap-y-8 ">
              <div className="lg:col-span-3 sm1:col-span-6 col-span-12 ">
                <div className=" xl:w-24 xl:h-24 lg:w-[88px] lg:h-[88px] w-20 h-20  bg-white lg:rounded-[18px] rounded-xl flex items-center justify-center">
                  <img
                    src={convenience}
                    alt="convenience"
                    className=" xl:w-16 xl:h-16 lg:w-14 lg:h-14 w-14 h-14"
                  />
                </div>
                <h2 className="font-semibold xl:text-2xl md1:text-2xl sm1:text-xl text-lg mt-6 mb-4">
                  Convenience at Your Fingertips:
                </h2>
                <p className="font-semibold xl:text-lg md1:text-base sm1:text-sm text-base">
                  With just a few taps, you can find top-rated car washes near
                  you. Filter by price, location, or services to find exactly
                  what your car needs.
                </p>
              </div>

              <div className="lg:col-span-3 sm1:col-span-6 col-span-12">
                <div className="xl:w-24 xl:h-24 lg:w-[88px] lg:h-[88px] w-20 h-20 bg-white lg:rounded-[18px] rounded-xl flex items-center justify-center">
                  <img
                    src={timer}
                    alt="timer"
                    className=" xl:w-16 xl:h-16 lg:w-14 lg:h-14 w-14 h-14"
                  />
                </div>
                <h2 className="font-semibold xl:text-2xl md1:text-2xl sm1:text-xl text-lg mt-6 mb-4">
                  Save Time and Effort:
                </h2>
                <p className="font-semibold xl:text-lg md1:text-base sm1:text-sm text-base">
                  No more driving around searching for a car wash. With Car Wash
                  Near Me, all the best options are in one place, making it fast
                  and hassle-free.
                </p>
              </div>

              <div className="lg:col-span-3 sm1:col-span-6 col-span-12">
                <div className="xl:w-24 xl:h-24 lg:w-[88px] lg:h-[88px] w-20 h-20  bg-white lg:rounded-[18px] rounded-xl flex items-center justify-center">
                  <img
                    src={discountshape}
                    alt="discountshape"
                    className="xl:w-16 xl:h-16 lg:w-14 lg:h-14 w-14 h-14 "
                  />
                </div>
                <h2 className="font-semibold xl:text-2xl md1:text-2xl sm1:text-xl text-lg mt-6 mb-4">
                  Exclusive Offers:
                </h2>
                <p className="font-semibold xl:text-lg md1:text-base sm1:text-sm text-base">
                  Access special deals, promotions, and loyalty programs from
                  car washes across the UAE. Get more value every time you book!
                </p>
              </div>

              <div className="lg:col-span-3 sm1:col-span-6 col-span-12">
                <div className="xl:w-24 xl:h-24 lg:w-[88px] lg:h-[88px] w-20 h-20 bg-white lg:rounded-[18px] rounded-xl flex items-center justify-center">
                  <img
                    src={quality}
                    alt="quality"
                    className="xl:w-16 xl:h-16 lg:w-14 lg:h-14 w-14 h-14 "
                  />
                </div>
                <h2 className="font-semibold xl:text-2xl md1:text-2xl sm1:text-xl text-lg mt-6 mb-4">
                  Verified Reviews:
                </h2>
                <p className="font-semibold xl:text-lg md1:text-base sm1:text-sm text-base">
                  Check out honest customer reviews to help you choose the best
                  car wash for your car’s needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="faqs">
        <div className="bg-[#F9F9F9]">
          <div className="containers md1:py-[100px] py-14">
            <div className="mb-16">
              <h2 className="4xl:text-[40px] 2xl:text-4xl  md1:text-3xl sm1:text-2xl text-xl 4xl:leading-[62px] sm:leading-10 leading-[50px] font-medium text-center text-[#4169E1] underline xl:underline-offset-[15px] underline-offset-[13px] decoration-2 ">
                Frequently Asked Questions
              </h2>
            </div>

            <div className="2xl:w-[79rem] xl:w-[65rem] lg:w-[55rem] md:w-[45rem] mx-auto">
              {questions.map((faq, index) => (
                <div
                  key={index}
                  className="bg-white rounded-xl lg:p-8 sm1:p-7 p-4 lg:mb-[30px] sm1:mb-6 mb-5 hover:bg-[#4169E1] hover:text-white "
                >
                  <div
                    className="flex justify-between items-center cursor-pointer "
                    onClick={() => toggleAnswer(index)}
                  >
                    <h3 className="sm1:text-xl sm:text-lg text-base font-medium">
                      {faq.question}
                    </h3>
                    <span className="xl:text-2xl sm1:text-xl sm:text-lg text-base font-medium pl-10">
                      {selected === index ? <RiSubtractFill /> : <RiAddFill />}
                    </span>
                  </div>
                  {selected === index && (
                    <p className="mt-5 sm1:text-lg sm:text-base text-sm font-normal ">
                      {faq.answer}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section id="contact">
        <div className="sm:relative sm:z-10 sm:-mb-80">
          <div className="4xl:mx-[150px] 2xl:mx-[100px] xl:mx-20 lg:mx-[70px] md:mx-[60px] sm1:mx-10 sm1:mt-12">
            <div
              className="text-white bg-cover bg-no-repeat sm1:rounded-[30px] bg-center py-10"
              style={{ backgroundImage: `url(${bgimg4})` }}
            >
              <div className="sm:mx-0 mx-5">
                <div className="mb-8 ">
                  <h2 className="4xl:text-[40px] 2xl:text-4xl  md1:text-3xl sm1:text-2xl text-xl  4xl:leading-[62px] sm:leading-10 leading-[50px] font-medium text-center underline underline-offset-[15px]  md:decoration-3 decoration-2 ">
                    Get In Touch
                  </h2>
                </div>
                <div className="sm:flex items-center justify-center">
                  <form onSubmit={formik.handleSubmit}>
                    <div>
                      <input
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Full Name"
                        autoComplete="off"
                        className="rounded-xl md:w-[570px]  md:h-14 sm1:w-[450px] sm:w-[400px] w-full h-12 px-5  outline-none text-black text-base font-medium"
                        value={formik.values.name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      ></input>
                      {formik.errors.name && formik.touched.name && (
                        <p className="text-red-600 text-start text-sm font-medium">
                          {formik.errors.name}
                        </p>
                      )}
                    </div>
                    <div className="my-8">
                      <input
                        id="email"
                        type="email"
                        name="email"
                        autoComplete="off"
                        placeholder="Email Address"
                        className="rounded-xl md:w-[570px] md:h-14 sm1:w-[450px] sm:w-[400px] w-full h-12 px-5 outline-none text-black text-base font-medium "
                        value={formik.values.email}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      ></input>
                      {formik.errors.email && formik.touched.email && (
                        <p className="text-red-600 text-start text-sm font-medium">
                          {formik.errors.email}
                        </p>
                      )}
                    </div>
                    <div>
                      <textarea
                        id="message"
                        autoComplete="off"
                        className="rounded-xl md:w-[570px] sm1:w-[450px] w-full sm:w-[400px]   px-5 pt-4 outline-none text-black text-base font-medium"
                        rows={5}
                        placeholder="Type Your Massage Here..."
                        name="message"
                        value={formik.values.message}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      ></textarea>
                      {formik.errors.message && formik.touched.message && (
                        <p className="text-red-600 text-start text-sm font-medium">
                          {formik.errors.message}
                        </p>
                      )}
                    </div>
                    <div className="flex justify-center items-center mt-10">
                      <button
                        type="submit"
                        className="bg-[#13161F] text-center h-14 w-60 rounded-full text-base font-medium "
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Homepage;
