import React, { useState } from "react";
import bgimg5 from "../../Assets/images/bgimg5.png";
import androidimg from "../../Assets/images/androidimg.png";
import iosimg from "../../Assets/images/iosimg.png";
import "../../App.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import animationData from "../../Assets/json/Animation - 1726721290403.json";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  fname: Yup.string().required("First Name is required"),
  lname: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phoneno: Yup.string().required("Phone number is required"),

  password: Yup.string().required("Password is required"),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  address: Yup.string().required("Street Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zipcode: Yup.string().required("Zip Code is required"),
});

function RegisterBusinesspage() {
  const navigate = useNavigate();
  const [flag, IsSetFlag] = useState(0);

  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      phoneno: "",
      password: "",
      confirmpassword: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      IsSetFlag(1);
      resetForm();
    },
  });

  return (
    <>
      <section>
        <div className="w-full h-[550px] text-white text-center bg-[#111927]">
          <div className="containers md:pt-20 pt-14">
            <h2 className=" 4xl:text-[40px] 2xl:text-4xl md:text-3xl text-2xl leading-10 font-medium">
              Register Your Business
            </h2>
            <p className="sm:text-xl sm:leading-6 text-base font-medium md:mt-8 mt-6">
              Application From
            </p>
          </div>
        </div>
        <div className="md:-mt-[380px] -mt-[400px]">
          {flag === 0 ? (
            <div className="containers md:py-20 py-14 ">
              <div className="bg-white py-[100px] rounded-[30px] w-full px-4 flex justify-center shadow items-center">
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-6">
                    <input
                      id="fname"
                      type="text"
                      name="fname"
                      autoComplete="off"
                      placeholder="First Name"
                      className="xl:w-[520px] md:w-[500px] sm:w-[400px] w-full sm:h-[54px] h-10 px-5  rounded-xl bg-[#F9FAFD] sm:text-base text-sm font-medium border-none outline-none"
                      value={formik.values.fname}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.fname && formik.touched.fname && (
                      <p className="text-red-600  text-start ms-2 text-sm font-medium">
                        {formik.errors.fname}
                      </p>
                    )}
                  </div>
                  <div className="mb-6">
                    <input
                      id="lname"
                      type="text"
                      name="lname"
                      autoComplete="off"
                      placeholder="Last Name"
                      className="xl:w-[520px] md:w-[500px] sm:w-[400px] w-full sm:h-[54px] h-10  px-5 rounded-xl bg-[#F9FAFD] sm:text-base text-sm font-medium border-none outline-none"
                      value={formik.values.lname}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.lname && formik.touched.lname && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.lname}
                      </p>
                    )}
                  </div>
                  <div className="mb-6">
                    <input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      autoComplete="off"
                      className="xl:w-[520px] md:w-[500px] sm:w-[400px] w-full sm:h-[54px] h-10 px-5 rounded-xl bg-[#F9FAFD] sm:text-base text-sm font-medium border-none outline-none"
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.email}
                      </p>
                    )}
                  </div>
                  <div className=" mb-6">
                    <div className="xl:w-[520px] md:w-[500px] sm:w-[400px] w-full sm:h-[54px] h-10">
                      <PhoneInput
                        country={"us"}
                        value={formik.values.phoneno}
                        onChange={(phoneno) =>
                          formik.setFieldValue("phoneno", phoneno)
                        }
                        onBlur={formik.handleBlur}
                        className="sm:text-base text-sm font-medium"
                      />
                      {formik.errors.phoneno && formik.touched.phoneno && (
                        <p className="text-red-600 text-start ms-2 text-sm font-medium">
                          {formik.errors.phoneno}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mb-6">
                    <input
                      id="password"
                      type="password"
                      name="password"
                      autoComplete="off"
                      placeholder=" Password"
                      className="xl:w-[520px] md:w-[500px] sm:w-[400px] w-full sm:h-[54px] h-10 px-5 rounded-xl bg-[#F9FAFD] sm:text-base text-sm font-medium border-none outline-none"
                      value={formik.values.password}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.password && formik.touched.password && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.password}
                      </p>
                    )}
                  </div>
                  <div className="mb-6">
                    <input
                      id="confirmpassword"
                      type="password"
                      name="confirmpassword"
                      autoComplete="off"
                      placeholder="Confirm-Password"
                      className="xl:w-[520px] md:w-[500px] sm:w-[400px] w-full sm:h-[54px] h-10 px-5 rounded-xl bg-[#F9FAFD] sm:text-base text-sm font-medium border-none outline-none"
                      value={formik.values.confirmpassword}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.confirmpassword &&
                      formik.touched.confirmpassword && (
                        <p className="text-red-600 text-start ms-2 text-sm font-medium">
                          {formik.errors.confirmpassword}
                        </p>
                      )}
                  </div>
                  <div className="mb-6">
                    <input
                      id="address"
                      type="text"
                      name="address"
                      autoComplete="off"
                      placeholder="Street Address"
                      className="xl:w-[520px] md:w-[500px] sm:w-[400px] w-full sm:h-[54px] h-10 px-5 rounded-xl bg-[#F9FAFD] sm:text-base text-sm font-medium border-none outline-none"
                      value={formik.values.address}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.address && formik.touched.address && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.address}
                      </p>
                    )}
                  </div>
                  <div className="mb-6">
                    <input
                      id="city"
                      type="text"
                      name="city"
                      placeholder="City"
                      autoComplete="off"
                      className="xl:w-[520px] md:w-[500px] sm:w-[400px] w-full sm:h-[54px] h-10 px-5 rounded-xl bg-[#F9FAFD] sm:text-base text-sm font-medium border-none outline-none"
                      value={formik.values.city}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.city && formik.touched.city && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.city}
                      </p>
                    )}
                  </div>
                  <div className="mb-6">
                    <input
                      id="state"
                      type="text"
                      name="state"
                      placeholder="State"
                      autoComplete="off"
                      className="xl:w-[520px] md:w-[500px] sm:w-[400px] w-full sm:h-[54px] h-10 px-5 rounded-xl bg-[#F9FAFD] sm:text-base text-sm font-medium border-none outline-none"
                      value={formik.values.state}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.state && formik.touched.state && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.state}
                      </p>
                    )}
                  </div>
                  <div className="mb-6">
                    <input
                      id="zipcode"
                      type="text"
                      name="zipcode"
                      placeholder="Zip Code"
                      autoComplete="off"
                      className="xl:w-[520px] md:w-[500px] sm:w-[400px] w-full sm:h-[54px] h-10 px-5 rounded-xl bg-[#F9FAFD] sm:text-base text-sm font-medium border-none outline-none"
                      value={formik.values.zipcode}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.zipcode && formik.touched.zipcode && (
                      <p className="text-red-600 text-start ms-2 text-sm font-medium">
                        {formik.errors.zipcode}
                      </p>
                    )}
                  </div>
                  <div className="flex justify-center items-center">
                    <button
                      className="bg-[#4169E1] text-white font-medium text-base text-center rounded-full md:h-[54px] md:w-[248px] h-12 sm:w-56  w-48 mt-6"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div>
              <div className="containers ">
                <div className=" md:my-20 my-14">
                  <div className="bg-white md:py-[90px] pb-14 rounded-[30px] w-full px-4 ">
                    <div className="flex justify-center items-center">
                      <Lottie
                        animationData={animationData}
                        loop={true}
                        autoplay={true}
                        alt="submit successfully"
                        className="h-40 w-40"
                      />
                    </div>
                    <div className="md:py-10 pb-10">
                      <h2 className="sm:text-[28px] text-2xl text-black leading-8 text-center font-semibold mb-8">
                        Submission successful
                      </h2>
                      <div className="sm:text-base text-sm text-[#7E7E7E] text-center font-medium mx-auto md:w-[510px] sm1:w-[450px]  space-y-5">
                        <p>We have received your application, thank you.</p>
                        <p>
                          Our team will review your application within the next
                          48 hours and contact you via email. We might request
                          interviews or further assessments as part of the
                          vetting process.
                        </p>
                        <p>
                          If your application is successful, we will send you an
                          email with log in details for your CW NEAR ME
                          dashboard.
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-center items-center">
                      <button
                        className="bg-[#4169E1] text-white font-medium  text-base text-center rounded-full md:h-[54px] md:w-[248px] h-12 sm:w-56 w-48 outline-none"
                        type="button"
                        onClick={() => {
                          navigate("/");
                        }}
                      >
                        Finish
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="cover "
                style={{ backgroundImage: `url(${bgimg5})` }}
              >
                <div className=" text-white">
                  <div className="containers sm1:py-[100px] py-10">
                    <div className="grid grid-cols-12 lg:gap-x-10 sm1:gap-x-8 gap-y-3  items-center ">
                      <div className="sm1:col-span-6 col-span-12 ">
                        <h2 className="font-medium 4xl:text-[40px] 2xl:text-4xl  md1:text-3xl sm1:text-2xl text-xl  4xl:leading-[62px] md:leading-10 sm1:leading-7 leading-10 2xl:mb-6 md:mb-4 sm1:mb-3 mb-6 text-start">
                          Download Car Wash Near Me Mobile App Now
                        </h2>
                        <p className="2xl:leading-7 sm1:leading-5 leading-7  2xl:text-lg lg:text-base md:text-sm sm1:text-xs text-base  font-medium 4xl:mb-10 2xl:mb-8 lg:mb-6 sm1:mb-5 mb-6 text-start">
                          Get our app to book car services and get your car
                          washed —download now!
                        </p>
                      </div>
                      <div className="sm1:col-span-6 col-span-12  ">
                        <div className="sm1:flex grid items-center 2xl:gap-x-10 lg:gap-x-8 md1:gap-x-6 sm1:gap-x-5 sm1:gap-y-0 gap-y-8 place-items-center">
                          <img
                            src={iosimg}
                            alt="iosimg"
                            className=" xl:h-60 xl:w-60 lg:h-[220px] lg:w-[205px] md:h-44 md:w-[170px] md1:w-[140px] md1:h-36 sm1:w-[105px] sm1:h-[120px] xs:w-64 xs:h-64 h-60"
                          />
                          <img
                            src={androidimg}
                            alt="androidimg"
                            className=" xl:h-60 xl:w-60 lg:h-[220px] lg:w-[205px] md:h-44 md:w-[170px] md1:w-[140px] md1:h-36 sm1:w-[105px] sm1:h-[120px] xs:w-64 xs:h-64 h-60"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
export default RegisterBusinesspage;
